<template>
  <select v-model="selected" class="browser-default custom-select">
    <option v-for="opt in selectOptions" :key="opt" :value="opt">
      {{ opt }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [10, 25, 50, 100]
    },
    initSelected: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      selectOptions: this.options,
      selected: this.initSelected
    };
  },

  watch: {
    selected: function() {
      this.$emit("items-per-page-changed", this.selected);
    }
  }
};
</script>
