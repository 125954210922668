// noinspection JSUnresolvedFunction
// Suppress warnings from IDE not recognizing the 'component', etc. functions.

/* FontAwesome */
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {dom} from "@fortawesome/fontawesome-svg-core";
/* Playr */
import VuePlyr from "vue-plyr";
/* Ziggy */
import {ZiggyVue} from "ziggy";
import {Ziggy} from "./ziggy.js";
/* Notifications (Toasts) */
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
/* Loading UI Blocker */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
/* Global mixins */
import InteractsWithApi from "./mixins/InteractsWithApi";
/* Promisifying Dialogs */
import * as ModalDialogs from "vue-modal-dialogs";

/**
 * Globally registering base components.
 */
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

/* Bootstrap contains the CSRF protection. */
require("./bootstrap");

/* Require Vue this way for current Webpack version. */
const Vue = require("vue");

/* Global CSRF in all XHR calls. */
const csrf_token = document.head.querySelector('meta[name="csrf-token"]').content;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;

/* Imports, Requirements, and their configs. -------------------------------- */

Vue.component("font-awesome-icon", FontAwesomeIcon);
dom.watch();

Vue.use(VuePlyr, {
  plyr: {
    controls: [
      "play-large",
      "restart",
      "rewind",
      "play",
      "fast-forward",
      "progress",
      "current-time",
      "duration",
      "mute",
      "volume",
      "fullscreen",
      "settings"
    ],
    fullscreen: {enabled: true}
  },
  emit: ["ended"]
});

Vue.use(ZiggyVue, Ziggy)

Vue.use(Toast, {});

Vue.component("loading", Loading);
Vue.use(Loading, {
  canCancel: false,
  color: "#000000",
  loader: "bars",
  backgroundColor: "#ffffff",
  opacity: 0.4
});

/* Moment (to be removed) */
const moment = require('moment-timezone');
Vue.use(require("vue-moment"), {moment});

Vue.mixin(InteractsWithApi);

Vue.use(ModalDialogs);

/* Laravel Vue Pagination */
Vue.component("pagination", require("laravel-vue-pagination"));

/*
 * TODO: Determine whether the following definition of window constants is
 * required.
 *
 * Some components appear to work fine using just the global constants. Others,
 * particularly those using mixins, appear to need it defined as part of the
 * window.
 */
window.Vue = Vue;
window.moment = moment;

// Register Base Components. ---------------------------------------------------
const baseComponents = require.context(
  "./components/ui",
  true,
  /Base[A-Z]\w+\.(vue|js)$/
);
baseComponents.keys().forEach(filename => {
  const componentConfig = baseComponents(filename);
  const componentName = upperFirst(
    camelCase(filename.split("/").pop().replace(/\.\w+$/, ""))
  );
  /*
   * Look for the component options on `.default`, which will
   * exist if the component was exported with `export default`,
   * otherwise fall back to module's root.
   */
  Vue.component(componentName, componentConfig.default || componentConfig);
});

// Register Custom Components --------------------------------------------------

// Mailing List ----------------------------------------------------------------
Vue.component("mailing-list-manager", () =>
  import("./components/mailing/list/Manager.vue"));
Vue.component("mailing-list-messages-manager", () =>
  import("./components/mailing/messages/Manager.vue"))
Vue.component("mailing-list-messages-editor", () =>
  import("./components/mailing/messages/Editor.vue"))
Vue.component("mailing-list-email-manager", () =>
  import("./components/mailing/list/email/Manager.vue"))


// General Purpose Components
Vue.component("pre-loader-spinner", () =>
  import("./components/mdb/PreLoaderSpinner.vue"));
Vue.component("home-dashboard", () =>
  import("./components/dashboard/HomeDashboard.vue"));

Vue.component("users-manager", () =>
  import("./components/users/manage/UsersManager.vue")
);
Vue.component("user-manage", () =>
  import("./components/users/manage/UserManage.vue")
);
Vue.component("user-profile", () =>
  import("./components/users/manage/UserProfile.vue")
);

Vue.component("login-register-forms", () =>
  import("./components/auth/LoginRegisterForms.vue")
);

Vue.component("purchasing-products", () =>
  import("./components/purchasing/PurchasingProducts.vue")
);
Vue.component("checkout", () => import("./components/purchasing/Checkout.vue"));

Vue.component("the-page-listing", () =>
  import("./components/pages/ThePageListing.vue")
);
Vue.component("page-editor", () => import("./components/pages/PageEditor.vue"));

Vue.component("user-license", () =>
  import("./components/licenses/UserLicense.vue")
);
Vue.component("user-licenses-subscriptions-table", () =>
  import("./components/licenses/UserLicensesSubscriptionsTable.vue")
);
Vue.component("subscriptions-manager", () =>
  import("./components/licenses/SubscriptionsManager.vue")
);

Vue.component("video-manager", () =>
  import("./components/video-management/VideoManager.vue")
);
Vue.component("video-playlist-manager", () =>
  import("./components/video-management/VideoPlaylistManager.vue")
);
Vue.component("video-playlist", () =>
  import("./components/video-management/VideoPlaylist.vue")
);


/** Admin Components. */
Vue.component("admin-permissions-manager", () =>
  import("./components/admin/PermissionsManager.vue")
);

Vue.component("admin-product-manager", () =>
  import("./components/admin/ProductManager.vue")
);
Vue.component("admin-product-listing", () =>
  import("./components/admin/ProductListing.vue")
);
Vue.component("admin-product-dialog", () =>
  import("./components/admin/ProductDialog.vue")
);

Vue.component("admin-role-manager", () =>
  import("./components/admin/RoleManager.vue")
);
Vue.component("admin-settings-manager", () =>
  import("./components/admin/SettingsManager.vue")
);

Vue.component('module-licensing-manager', () =>
  import('./components/module-licensing/ModuleLicensingManager.vue')
);

/**
 * Create application instance.
 */

new Vue({
  el: "#app",

  /* Re-enable tooltips whenever the app is updated. */
  updated: function () {
    // noinspection JSUnresolvedVariable
    if (!window.printLayout) {
      $('[data-toggle="tooltip"]').tooltip();
    }
  }
});
