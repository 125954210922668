<template>
  <a>
    <span class="mr-1">
      <font-awesome-icon :icon="['fas', 'angle-down']" v-if="isExpanded" />
      <font-awesome-icon :icon="['fas', 'angle-right']" v-else />
    </span>
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    isExpanded: {
      type: Boolean,
      required: true
    }
  }
};
</script>
